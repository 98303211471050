import * as React from 'react';
import './Group.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../../../stores';
import { IGroup } from '../../../../../interfaces/IGroup';
import GroupService from '../../../../../services/GroupService';
import { IMyGroup } from '../../../../../interfaces/IMyGroup';

export interface IProps {
  appStore?: AppStore;
  group: IMyGroup;
  style?: React.CSSProperties;
  getMyGroups: (group: IMyGroup) => void;
}

export interface IState {
  hasAccess: boolean;
}

@inject('appStore')
@observer
export default class Group extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      hasAccess: false
    }
  }

  render() {
    return (
      <div
        className='groupInvitation'
        style={this.props.style}
      >
        {this.props.group?.group?.admins?.includes(this.props.appStore.user?.id) &&
          <div
            className="groupInvitationEditButton"
            style={{
              backgroundImage: `url("https://img.icons8.com/external-anggara-basic-outline-anggara-putra/96/ffffff/external-edit-user-interface-anggara-basic-outline-anggara-putra-5.png")`,
            }}
            onClick={() => {
              this.props.appStore.groupToEdit = this.props.group.group;
            }}
          />
        }
        <h2>{this.props.group?.group?.name}</h2>
        <div
          className='groupInvitationDescription'
          style={{
            height: this.props.style.height ? 120 : undefined
          }}>
          {this.props.group?.group?.description}
        </div>
        <div className="shareTypeToggle">
          {this.props.group?.groupMemberInfo == undefined ?
            <div
              className="enneagrammyCreateGroupBtn"
              style={{
                opacity: this.props.appStore.myResult == undefined ? 0.5 : 1,
              }}
              onClick={() => {
                if (this.props.appStore.myResult != undefined) {
                  this.props.group.groupMemberInfo = {
                    groupId: this.props.group.group.objectId,
                    userId: this.props.appStore.user.id,
                    type: this.props.appStore.selectedType
                  };
                  GroupService.createGroupMemberInfo(this.props.group.groupMemberInfo).then((id: string) => {
                    if (id != undefined) {
                      this.props.group.groupMemberInfo.objectId = id;
                      this.props.getMyGroups(this.props.group);
                    }
                  })
                }
              }}
            >
              {window.loc.strings.acceptInvitation.toUpperCase()}
            </div>
            :
            <div
              className="enneagrammyCreateGroupBtn"
              onClick={() => {
                GroupService.deleteGroupMemberInfo(this.props.group.groupMemberInfo.objectId).then(() => {
                  this.props.getMyGroups(this.props.group);
                });
              }}
              style={{
                backgroundColor: "gray"
              }}
            >
              {window.loc.strings.leaveGroup.toUpperCase()}
            </div>
          }
        </div>
        {((this.props.group?.group?.allowMembersToSeeResult && this.props.group?.groupMemberInfo != undefined) || this.props.group.group.admins.includes(this.props.appStore.user?.id)) &&
          <div
            className="enneagrammyCreateGroupBtn"
            style={{
              marginTop: 15,
            }}
            onClick={() => {
              this.props.appStore.groupToView = this.props.group.group;
            }}
          >
            {window.loc.strings.seeGroupResults.toUpperCase()}
          </div>
        }
      </div>
    );
  }
}