import * as React from 'react';
import './Profile.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { IType } from '../../interfaces/IType';
import { Link } from 'react-router-dom';
import { IMyGroup } from '../../interfaces/IMyGroup';
import Group from '../tests/enneagrammy/enneagrammyInfoPage/group/Group';
import GroupService from '../../services/GroupService';
import { IGroup } from '../../interfaces/IGroup';

export interface IProps {
  show: boolean;
  appStore?: AppStore;
}

export interface IState {
  showProfileBackground: boolean;
  loadingGroups: boolean;
}

@inject('appStore')
@observer
export default class Profile extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      showProfileBackground: false,
      loadingGroups: true
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.show && !this.props.show) {
      setTimeout(() => {
        this.setState({ showProfileBackground: true });
        this.getMyGroups();
      }, 0)
    };
  }

  private getTypeName(type: number): string {
    switch (type) {
      case 1:
        return window.loc.strings.type1;
      case 2:
        return window.loc.strings.type2;
      case 3:
        return window.loc.strings.type3;
      case 4:
        return window.loc.strings.type4;
      case 5:
        return window.loc.strings.type5;
      case 6:
        return window.loc.strings.type6;
      case 7:
        return window.loc.strings.type7;
      case 8:
        return window.loc.strings.type8;
      case 9:
        return window.loc.strings.type9;
      default:
        break;
    }
  }

  private async getMyGroups(): Promise<void> {
    if (this.props.appStore?.user?.id) {
      const myGroups = [];
      this.setState({ loadingGroups: true });
      await GroupService.getGroupsForUser(this.props.appStore?.user?.id).then((groups: IGroup[]) => {
        this.props.appStore.groups = groups;
        this.props.appStore.groups?.forEach((group: IGroup, index) => {
          GroupService.getMyGroupMemberInfo(group.objectId, this.props.appStore?.user?.id).then((groupMemberInfo: any,) => {
            myGroups.push({
              group,
              groupMemberInfo: groupMemberInfo?.results?.length > 0 ? groupMemberInfo?.results[0] : undefined
            });
            // if (index === this.props.appStore.groups?.length - 1) {
            this.props.appStore.myGroups = myGroups;
            this.setState({ loadingGroups: false });
            // }
          });
        });
      });
    }
  }

  render() {
    const groups = [];
    if (this.props.appStore.groups?.length > 0) {
      this.props.appStore.myGroups.forEach((myGroup: IMyGroup) => {
        groups.push(
          <Group
            key={myGroup.group.objectId}
            style={{
              width: 420,
            }}
            group={myGroup}
            getMyGroups={() => this.getMyGroups()}
          />
        );
      });
    }
    return (
      <>
        {this.props.appStore.showProfile &&
          <div
            className='profileOverlay'
            style={{
              opacity: this.state.showProfileBackground ? 0.6 : 0
            }}
          />
        }
        <div
          className='profile'
          style={{
            top: this.props.appStore.isMobile ? (this.props.appStore.showProfile ? 0 : screen.height) : 50,
            right: this.props.appStore.isMobile ? "unset" : (this.props.appStore.showProfile ? 0 : -500),
            height: this.props.appStore.isMobile ? "100%" : "calc(100% - 50px)",
          }}
        >
          <div
            className='closeButton'
            onClick={() => {
              this.props.appStore.showProfile = false;
            }}
          />
          <div className="profileWrapper">
            <div className="headline">{window.loc.strings.yourProfile}</div>
            <div className='areaHeadline'>{window.loc.strings.yourTestResult.toUpperCase()}</div>
            <div className='enneagramTypeBox'>
              <div className='profileEnneagramTypeResultBox'>
                <div
                  className="enneagrammyResultNumber"
                  style={{
                    backgroundColor: this.props.appStore.typeTestColorLighter
                  }}
                >
                  {this.props.appStore.selectedType}
                </div>
                <div className="enneagrammyResultName">
                  {this.getTypeName(this.props.appStore.selectedType)}
                </div>
                <div style={{
                  float: "left",
                  clear: "both",
                  width: "100%",
                  height: 50,
                }}>
                  {this.props.appStore.isMobile ?
                    <a
                      href="https://apps.apple.com/us/app/enneagrammy/id1033881087"
                      target="_blank"
                    >
                      <div
                        className='profileButton'
                        style={{
                          backgroundColor: "#064859",
                          color: "#ffffff",
                        }}
                      >
                        <div
                          className='profileButtonIcon'
                          style={{
                            backgroundImage: 'url("https://dojo.mindjuice.com/images/tabbar/tests.png")'
                          }}
                        />
                        <div className='profileButtonText' >
                          {window.loc.strings.testIntroStartBtn}
                        </div>
                      </div>
                    </a>
                    :
                    <Link
                      to={`${this.props.appStore.environment}/tests/19067/test`}
                    >
                      <div
                        className='profileButton'
                        style={{
                          backgroundColor: "#064859",
                          color: "#ffffff",
                        }}
                        onClick={() => {
                          this.props.appStore.startTest();
                          this.props.appStore.testProgression = "Test";
                          this.props.appStore.showEnneagrammyTest = true;
                        }}
                      >
                        <div
                          className='profileButtonIcon'
                          style={{
                            backgroundImage: 'url("https://dojo.mindjuice.com/images/tabbar/tests.png")'
                          }}
                        />
                        <div className='profileButtonText' >
                          {window.loc.strings.testIntroStartBtn}
                        </div>
                      </div>
                    </Link>
                  }
                  {this.props.appStore.isMobile ?
                    <a
                      href="https://apps.apple.com/us/app/enneagrammy/id1033881087"
                      target="_blank"
                    >
                      <div
                        className='profileButton'
                      >
                        <div
                          className='profileButtonIcon'
                          style={{
                            backgroundImage: 'url("https://dojo.mindjuice.com/images/result-icon.png")'
                          }}
                        />
                        <div className='profileButtonText' >
                          {window.loc.strings.resultTextBtn}
                        </div>
                      </div>
                    </a>
                    :
                    <Link
                      to={`${this.props.appStore.environment}/tests/19067/results`}
                    >
                      <div
                        className='profileButton'
                        onClick={() => {
                          this.props.appStore.selectedTypeInfoTab = "result";
                          this.props.appStore.showEnneagrammyTestResults = true;
                          this.props.appStore.selectedTestResult = this.props.appStore.myResult;
                        }}
                      >
                        <div
                          className='profileButtonIcon'
                          style={{
                            backgroundImage: 'url("https://dojo.mindjuice.com/images/result-icon.png")'
                          }}
                        />
                        <div className='profileButtonText' >
                          {window.loc.strings.resultTextBtn}
                        </div>
                      </div>
                    </Link>
                  }
                </div>
              </div>
            </div>
            {this.props.appStore.user?.userType === "admin" &&
              <div className='groups'>
                <div className='areaHeadline'>{window.loc.strings.groups.toUpperCase()}</div>
                {this.state.loadingGroups ? <div className='loadingGroups'>{window.loc.strings.loading}</div> : groups}
              </div>
            }
          </div>
        </div>
      </>
    );
  }

}