import * as React from 'react';
import './GroupsPanel.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../../stores';
import { IUser } from '../../../../interfaces/IUser';
import AdminService from '../../../../services/AdminService';
import UserSearchPopup from '../../../userSearchPopup/UserSearchPopup';

export interface IProps {
  id: string;
  appStore?: AppStore;
  save: () => void;
  delete: () => void;
}

export interface IState {
  searchValue: string;
  usersToRender: JSX.Element[];
  loadingUsers: boolean;
  showMembers: boolean;
  members?: IUser[];
  showAdmins: boolean;
  admins?: IUser[];
}

export interface IState {
  selectedAudience: "all" | "teams" | "users";
}

@inject('appStore')
@observer
export default class GroupsPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      selectedAudience: "all",
      searchValue: "",
      usersToRender: [],
      loadingUsers: false,
      showMembers: false,
      showAdmins: false
    }
  }

  private getMembers() {
    const members = [];
    this.props.appStore.groupToEdit.members.forEach((member: string) => {
      AdminService.getUserNameFromId(member).then((user: IUser) => {
        members.push(user);
        this.setState({ members });
      });
    });
  }

  private getAdmins() {
    const admins = [];
    this.props.appStore.groupToEdit.admins.forEach((admin: string) => {
      AdminService.getUserNameFromId(admin).then((user: IUser) => {
        admins.push(user);
        this.setState({ admins });
      });
    });
  }

  render() {
    const members = [];
    if (this.state.members?.length > 0 && this.state.showMembers) {
      this.state.members.forEach((member: IUser) => {
        members.push(
          <div
            key={"groupMember-" + member}
            className="emailLabel"
          >
            {member.name}
            <div
              className="emailLabelDeleteBtn"
              onClick={() => {
                this.props.appStore.groupToEdit.members = this.props.appStore.groupToEdit?.members.filter(savedMember => savedMember !== member.id);
              }}
            />
          </div>
        );
      });
    }
    const admins = [];
    if (this.state.admins?.length > 0 && this.state.showAdmins) {
      this.state.admins.forEach((admin: IUser) => {
        admins.push(
          <div
            key={"groupMember-" + admin}
            className="emailLabel"
          >
            {admin.name}
            <div
              className="emailLabelDeleteBtn"
              onClick={() => {
                this.props.appStore.groupToEdit.admins = this.props.appStore.groupToEdit?.admins.filter(savedAdmin => savedAdmin !== admin.id);
              }}
            />
          </div>
        );
      });
    }
    return (
      <div>
        <div
          className="groupEditPanelWrapper"
          style={{
            visibility: this.props.appStore.groupToEdit != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.groupToEdit = undefined;
          }}
        />
        <div
          className="groupEditPanel"
          style={{
            right: this.props.appStore.groupToEdit != undefined ? 0 : -620
          }}
        >
          <div className="editPanelCloseBtn"
            onClick={() => this.props.appStore.groupToEdit = undefined}
          />
          <h3>{this.props.appStore.groupToEdit?.objectId ? window.loc.strings.updateGroup : window.loc.strings.createGroup}</h3>
          {this.props.appStore.groupToEdit != undefined &&
            <div style={{
              position: "relative",
              float: "left",
              width: "100%"
            }}>
              <div className="inputFieldLabel">{window.loc.strings.groupName}</div>
              <input
                type="text"
                value={this.props.appStore.groupToEdit.name}
                onChange={(event: any) => {
                  this.props.appStore.groupToEdit.name = event.target.value;
                }}
              />
              <div className="inputFieldLabel">{window.loc.strings.messageToMembers}</div>
              <textarea
                onChange={(event: any) => this.props.appStore.groupToEdit.description = event.target.value}
              >
                {this.props.appStore.groupToEdit?.description}
              </textarea>

              <div
                className="sideBarItemText"
                style={{
                  float: "left",
                  marginTop: 10,
                  clear: "both"
                }}
              >
                {window.loc.strings.allowMembersToSeeResult}
              </div>
              <label
                className={"IA_switchLabel"}
                style={{
                  float: "left",
                  marginTop: 10,
                  clear: "both"
                }}
              >
                <div
                  style={{
                    position: "relative",
                  }}
                  className="IA_OnFocus"
                  tabIndex={0}
                  role="switch"
                >
                  <input
                    type="checkbox"
                    tabIndex={-1}
                    checked={this.props.appStore.groupToEdit.allowMembersToSeeResult == undefined ? false : this.props.appStore.groupToEdit.allowMembersToSeeResult}
                    onChange={() => {
                      this.props.appStore.groupToEdit.allowMembersToSeeResult = this.props.appStore.groupToEdit.allowMembersToSeeResult == undefined ? true : !this.props.appStore.groupToEdit.allowMembersToSeeResult;
                    }}
                  />
                  <span
                    tabIndex={-1}
                    className={"IA_switchSlider"}
                    style={{
                      boxShadow: this.props.appStore.groupToEdit.allowMembersToSeeResult ? `0 0 1px #7a12d4` : "none",
                      background: this.props.appStore.groupToEdit.allowMembersToSeeResult ? "#7a12d4" : "#3c3c3c"
                    }}
                  ></span>
                </div>
              </label>
              {/* Admins */}
              <div
                style={{
                  marginTop: 20,
                  float: "left",
                  clear: "both"
                }}
              >
                <div className="inputFieldLabel">{window.loc.strings.groupAdmins}</div>
                <div className="groupMembers">
                  {this.state.showAdmins ? admins : (this.props.appStore.groupToEdit?.admins?.length === 1 ? window.loc.strings.oneAdmin : `${this.props.appStore.groupToEdit?.admins?.length ?? 0} ${window.loc.strings.admins}`)}
                </div>
                <button
                  onClick={() => this.setState({ showAdmins: !this.state.showAdmins }, () => this.getAdmins())}
                  disabled={this.props.appStore.groupToEdit?.admins == undefined || this.props.appStore.groupToEdit?.admins?.length === 0}
                  style={{
                    opacity: this.props.appStore.groupToEdit?.admins == undefined || this.props.appStore.groupToEdit?.admins?.length === 0 ? 0.5 : 1
                  }}
                >
                  {this.state.showAdmins ? window.loc.strings.hideAdmins : window.loc.strings.showAdmins}
                </button>
                <div className="inputFieldLabel">{window.loc.strings.addGroupAdmins}</div>
                <input
                  type="text"
                  placeholder={window.loc.strings.searchForUsers}
                  value={this.state.searchValue}
                  style={{
                    width: "100%"
                  }}
                  onChange={(event: any) => {
                    this.setState({ searchValue: event.target.value.toLowerCase() });
                  }}
                />
                <div
                  className="searchButton"
                  onClick={() => {
                    const usersToRender = [];
                    this.setState({ loadingUsers: true });
                    AdminService.getUsers(this.state.searchValue).then((users: IUser[]) => {
                      if (users && users.length > 0) {
                        users.forEach(async (user: IUser) => {
                          usersToRender.push(
                            <div
                              className='emailLabel'
                              onClick={() => {
                                if (!this.props.appStore.groupToEdit.admins) {
                                  this.props.appStore.groupToEdit.admins = [];
                                }
                                this.props.appStore.groupToEdit.admins.push(user.id);
                                this.setState({
                                  usersToRender: [],
                                  searchValue: undefined
                                });
                              }}
                            >
                              {`${user.name} (${user.username})`}
                            </div>
                          );
                        });
                        this.setState({ usersToRender, loadingUsers: false });
                      } else {
                        this.setState({ loadingUsers: false });
                      }
                    });
                  }}
                />
              </div>
              {/* Members */}
              <div
                style={{
                  marginTop: 20,
                  float: "left",
                  clear: "both"
                }}
              >
                <div className="inputFieldLabel">{window.loc.strings.groupMembers}</div>
                <div className="groupMembers">
                  {this.state.showMembers ? members : (this.props.appStore.groupToEdit?.members?.length === 1 ? window.loc.strings.oneMemeber : `${this.props.appStore.groupToEdit?.members?.length ?? 0} ${window.loc.strings.members}`)}
                </div>
                <button
                  onClick={() => this.setState({ showMembers: !this.state.showMembers }, () => this.getMembers())}
                  disabled={this.props.appStore.groupToEdit?.members == undefined || this.props.appStore.groupToEdit?.members?.length === 0}
                  style={{
                    opacity: this.props.appStore.groupToEdit?.members == undefined || this.props.appStore.groupToEdit?.members?.length === 0 ? 0.5 : 1
                  }}
                >
                  {this.state.showMembers ? window.loc.strings.hideMembers : window.loc.strings.showMembers}
                </button>
                <div className="inputFieldLabel">{window.loc.strings.addGroupMembers}</div>
                <input
                  type="text"
                  placeholder={window.loc.strings.searchForUsers}
                  value={this.state.searchValue}
                  style={{
                    width: "100%"
                  }}
                  onChange={(event: any) => {
                    this.setState({ searchValue: event.target.value.toLowerCase() });
                  }}
                />
                <div
                  className="searchButton"
                  onClick={() => {
                    const usersToRender = [];
                    this.setState({ loadingUsers: true });
                    AdminService.getUsers(this.state.searchValue).then((users: IUser[]) => {
                      if (users && users.length > 0) {
                        users.forEach(async (user: IUser) => {
                          usersToRender.push(
                            <div
                              className='emailLabel'
                              onClick={() => {
                                if (!this.props.appStore.groupToEdit.members) {
                                  this.props.appStore.groupToEdit.members = [];
                                }
                                this.props.appStore.groupToEdit.members.push(user.id);
                                this.setState({
                                  usersToRender: [],
                                  searchValue: undefined
                                });
                              }}
                            >
                              {`${user.name} (${user.username})`}
                            </div>
                          );
                        });
                        this.setState({ usersToRender, loadingUsers: false });
                      } else {
                        this.setState({ loadingUsers: false });
                      }
                    });
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: 20,
                  float: "left",
                  clear: "both"
                }}
              >
                <button
                  onClick={() => this.props.save()}
                >
                  {this.props.appStore.groupToEdit.objectId !== undefined && this.props.appStore.groupToEdit.objectId !== "new" ? window.loc.strings.update : window.loc.strings.create}
                </button>
                <button
                  style={{
                    marginLeft: 10
                  }}
                  onClick={() => {
                    this.props.appStore.groupToEdit = undefined;
                    this.props.appStore.productToNotify = undefined;
                  }}
                >
                  {window.loc.strings.cancel}
                </button>
                <button
                  onClick={() => this.props.delete()}
                  style={{
                    backgroundColor: "gray",
                    marginLeft: 10,
                    display: this.props.appStore.groupToEdit && this.props.appStore.groupToEdit.objectId != undefined ? "block" : "hidden"
                  }}
                >
                  {window.loc.strings.delete}
                </button>
              </div>
            </div>
          }
        </div>
        {this.state.usersToRender?.length > 0 &&
          <UserSearchPopup
            users={this.state.usersToRender}
            close={() => {
              this.setState({
                usersToRender: [],
                searchValue: undefined
              });
            }}
          />
        }
      </div>
    );
  }
}